import React, { useEffect, useLayoutEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
  ErrorMessage,
  StyledTextArea,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"

const FormPermissioningRegister = ( data ) => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const [isHowHeardOther, setIsHowHeardOther] = useState(false);

  let contactFormId
  
  data.eventType === 'online' ? contactFormId = 7938 // online contact form ID
  : contactFormId = 9461 // in person contact form ID

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }
    if (!values.address) {
      errors.address = "Required"
    }
    if (!values.city) {
      errors.city = "Required"
    }
    if (!values.postalCode) {
      errors.postalCode = "Required"
    }
    if (!values.country) {
      errors.country = "Required"
    }
    if (!values.telephone) {
      errors.telephone = "Required"
    }
    if (!values.howHear) {
      errors.howHear = "Required"
    }
    if (values.howHear === 'other') {
      setIsHowHeardOther(true)
      if (!values.howHearOther) {
        errors.howHearOther = "Required"
      }
    } else {
      setIsHowHeardOther(false)
    }
   
    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      address: "",
      city: "",
      postalCode: "",
      country: "",
      howHear: "",
      howHearOther: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        telephone,
        address,
        city,
        postalCode,
        country,
        howHear,
        howHearOther,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      //addToMailchimp(values.email, values)
      axios.post("/.netlify/functions/formHandler" , {firstName, lastName, emailAddress:email.toLowerCase() , telephone, address, city, postalCode, country, howHear })
          .then(data => {
            console.log('ERROR ',data)
          })
          .catch(console.log)
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("telephone", telephone)
      bodyFormData.set("address", address)
      bodyFormData.set("city", city)
      bodyFormData.set("postalCode", postalCode)
      bodyFormData.set("country", country)
      bodyFormData.set("howHear", howHear)
      bodyFormData.set("howHearOther", howHearOther)
      
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/${contactFormId}/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/events/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useLayoutEffect(() => {
    
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit} marginTop>
    <StyledFieldset>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="firstName">
        <StyledLabelHeader
            required
            isEmpty={errors.firstName && touched.firstName}
          > 
            First Name
          </StyledLabelHeader>
          <StyledInput
            id="firstName"
            name="firstName"
            type="text"
            onChange={handleChange}
            value={values.firstName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.firstName && touched.firstName ? (
          <span className="error-message">{errors.firstName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="lastName">
        <StyledLabelHeader
            required
            isEmpty={errors.lastName && touched.lastName}
          > 
            Last Name
          </StyledLabelHeader>
          <StyledInput
            id="lastName"
            name="lastName"
            type="text"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.lastName && touched.lastName ? (
          <span className="error-message">{errors.lastName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="email">
        <StyledLabelHeader
            required
            isEmpty={errors.email && touched.email}
          > 
            Email Address
          </StyledLabelHeader>
          <StyledInput
            id="email"
            name="email"
            type="email"
            onChange={handleChange}
            value={values.email}
            
            onBlur={handleBlur("email")}
            required
          />
        </StyledLabel>
        {errors.email && touched.email ? (
          <span className="error-message">{errors.email}</span>
        ) : null}
      </StyledFormElementWrapper>
      
      <StyledFormElementWrapper>
          <StyledLabel htmlFor="telephone">
            <StyledLabelHeader
            required
            isEmpty={errors.telephone && touched.telephone}
          > 
            Phone Number</StyledLabelHeader>
            
            <StyledInput
              id="telephone"
              name="telephone"
              type="tel"
              onChange={handleChange}
              value={values.telephone}
              placeholder="+44 (0)7400 123456"
              onBlur={handleBlur("telephone")}
              required
            />
          </StyledLabel>
          {errors.telephone && touched.telephone ? (
          <span className="error-message">{errors.telephone}</span>
        ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="address">
            <StyledLabelHeader
            required
            isEmpty={errors.address && touched.address}
          > 
            Address</StyledLabelHeader>
            
            <StyledInput
              id="address"
              name="address"
              type="text"
              onChange={handleChange}
              value={values.address}
              onBlur={handleBlur("address")}
              required
            />
          </StyledLabel>
          {errors.address && touched.address ? (
          <span className="error-message">{errors.address}</span>
        ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="city">
            <StyledLabelHeader
            required
            isEmpty={errors.city && touched.city}
          > 
            City</StyledLabelHeader>
            <StyledInput
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              value={values.city}
              onBlur={handleBlur("city")}
              required
            />
          </StyledLabel>
          {errors.city && touched.city ? (
          <span className="error-message">{errors.city}</span>
        ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="postalCode">
            <StyledLabelHeader
            required
            isEmpty={errors.postalCode && touched.postalCode}
          > 
            Postcode / ZIP</StyledLabelHeader>
            <StyledInput
              id="postalCode"
              name="postalCode"
              type="text"
              onChange={handleChange}
              value={values.postalCode}
              onBlur={handleBlur("postalCode")}
              required
            />
          </StyledLabel>
          {errors.postalCode && touched.postalCode ? (
          <span className="error-message">{errors.postalCode}</span>
        ) : null}
        </StyledFormElementWrapper>
        
        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="country">
            <StyledLabelHeader
              required
              isEmpty={errors.country && touched.country}
            >Country</StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="country"
                name="country"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                >
                <StyledOption value="default" disabled hidden>Please select?</StyledOption>  
                <StyledOption value="Afganistan">Afghanistan</StyledOption>
                <StyledOption value="Albania">Albania</StyledOption>
                <StyledOption value="Algeria">Algeria</StyledOption>
                <StyledOption value="American Samoa">American Samoa</StyledOption>
                <StyledOption value="Andorra">Andorra</StyledOption>
                <StyledOption value="Angola">Angola</StyledOption>
                <StyledOption value="Anguilla">Anguilla</StyledOption>
                <StyledOption value="Antigua &amp; Barbuda">Antigua &amp; Barbuda</StyledOption>
                <StyledOption value="Argentina">Argentina</StyledOption>
                <StyledOption value="Armenia">Armenia</StyledOption>
                <StyledOption value="Aruba">Aruba</StyledOption>
                <StyledOption value="Australia">Australia</StyledOption>
                <StyledOption value="Austria">Austria</StyledOption>
                <StyledOption value="Azerbaijan">Azerbaijan</StyledOption>
                <StyledOption value="Bahamas">Bahamas</StyledOption>
                <StyledOption value="Bahrain">Bahrain</StyledOption>
                <StyledOption value="Bangladesh">Bangladesh</StyledOption>
                <StyledOption value="Barbados">Barbados</StyledOption>
                <StyledOption value="Belarus">Belarus</StyledOption>
                <StyledOption value="Belgium">Belgium</StyledOption>
                <StyledOption value="Belize">Belize</StyledOption>
                <StyledOption value="Benin">Benin</StyledOption>
                <StyledOption value="Bermuda">Bermuda</StyledOption>
                <StyledOption value="Bhutan">Bhutan</StyledOption>
                <StyledOption value="Bolivia">Bolivia</StyledOption>
                <StyledOption value="Bonaire">Bonaire</StyledOption>
                <StyledOption value="Bosnia &amp; Herzegovina">Bosnia &amp; Herzegovina</StyledOption>
                <StyledOption value="Botswana">Botswana</StyledOption>
                <StyledOption value="Brazil">Brazil</StyledOption>
                <StyledOption value="British Indian Ocean Ter">British Indian Ocean Ter</StyledOption>
                <StyledOption value="Brunei">Brunei</StyledOption>
                <StyledOption value="Bulgaria">Bulgaria</StyledOption>
                <StyledOption value="Burkina Faso">Burkina Faso</StyledOption>
                <StyledOption value="Burundi">Burundi</StyledOption>
                <StyledOption value="Cambodia">Cambodia</StyledOption>
                <StyledOption value="Cameroon">Cameroon</StyledOption>
                <StyledOption value="Canada">Canada</StyledOption>
                <StyledOption value="Canary Islands">Canary Islands</StyledOption>
                <StyledOption value="Cape Verde">Cape Verde</StyledOption>
                <StyledOption value="Cayman Islands">Cayman Islands</StyledOption>
                <StyledOption value="Central African Republic">Central African Republic</StyledOption>
                <StyledOption value="Chad">Chad</StyledOption>
                <StyledOption value="Channel Islands">Channel Islands</StyledOption>
                <StyledOption value="Chile">Chile</StyledOption>
                <StyledOption value="China">China</StyledOption>
                <StyledOption value="Christmas Island">Christmas Island</StyledOption>
                <StyledOption value="Cocos Island">Cocos Island</StyledOption>
                <StyledOption value="Colombia">Colombia</StyledOption>
                <StyledOption value="Comoros">Comoros</StyledOption>
                <StyledOption value="Congo">Congo</StyledOption>
                <StyledOption value="Cook Islands">Cook Islands</StyledOption>
                <StyledOption value="Costa Rica">Costa Rica</StyledOption>
                <StyledOption value="Cote DIvoire">Cote DIvoire</StyledOption>
                <StyledOption value="Croatia">Croatia</StyledOption>
                <StyledOption value="Cuba">Cuba</StyledOption>
                <StyledOption value="Curaco">Curacao</StyledOption>
                <StyledOption value="Cyprus">Cyprus</StyledOption>
                <StyledOption value="Czech Republic">Czech Republic</StyledOption>
                <StyledOption value="Denmark">Denmark</StyledOption>
                <StyledOption value="Djibouti">Djibouti</StyledOption>
                <StyledOption value="Dominica">Dominica</StyledOption>
                <StyledOption value="Dominican Republic">Dominican Republic</StyledOption>
                <StyledOption value="East Timor">East Timor</StyledOption>
                <StyledOption value="Ecuador">Ecuador</StyledOption>
                <StyledOption value="Egypt">Egypt</StyledOption>
                <StyledOption value="El Salvador">El Salvador</StyledOption>
                <StyledOption value="Equatorial Guinea">Equatorial Guinea</StyledOption>
                <StyledOption value="Eritrea">Eritrea</StyledOption>
                <StyledOption value="Estonia">Estonia</StyledOption>
                <StyledOption value="Ethiopia">Ethiopia</StyledOption>
                <StyledOption value="Falkland Islands">Falkland Islands</StyledOption>
                <StyledOption value="Faroe Islands">Faroe Islands</StyledOption>
                <StyledOption value="Fiji">Fiji</StyledOption>
                <StyledOption value="Finland">Finland</StyledOption>
                <StyledOption value="France">France</StyledOption>
                <StyledOption value="French Guiana">French Guiana</StyledOption>
                <StyledOption value="French Polynesia">French Polynesia</StyledOption>
                <StyledOption value="French Southern Ter">French Southern Ter</StyledOption>
                <StyledOption value="Gabon">Gabon</StyledOption>
                <StyledOption value="Gambia">Gambia</StyledOption>
                <StyledOption value="Georgia">Georgia</StyledOption>
                <StyledOption value="Germany">Germany</StyledOption>
                <StyledOption value="Ghana">Ghana</StyledOption>
                <StyledOption value="Gibraltar">Gibraltar</StyledOption>
                <StyledOption value="Great Britain">Great Britain</StyledOption>
                <StyledOption value="Greece">Greece</StyledOption>
                <StyledOption value="Greenland">Greenland</StyledOption>
                <StyledOption value="Grenada">Grenada</StyledOption>
                <StyledOption value="Guadeloupe">Guadeloupe</StyledOption>
                <StyledOption value="Guam">Guam</StyledOption>
                <StyledOption value="Guatemala">Guatemala</StyledOption>
                <StyledOption value="Guinea">Guinea</StyledOption>
                <StyledOption value="Guyana">Guyana</StyledOption>
                <StyledOption value="Haiti">Haiti</StyledOption>
                <StyledOption value="Hawaii">Hawaii</StyledOption>
                <StyledOption value="Honduras">Honduras</StyledOption>
                <StyledOption value="Hong Kong">Hong Kong</StyledOption>
                <StyledOption value="Hungary">Hungary</StyledOption>
                <StyledOption value="Iceland">Iceland</StyledOption>
                <StyledOption value="Indonesia">Indonesia</StyledOption>
                <StyledOption value="India">India</StyledOption>
                <StyledOption value="Iran">Iran</StyledOption>
                <StyledOption value="Iraq">Iraq</StyledOption>
                <StyledOption value="Ireland">Ireland</StyledOption>
                <StyledOption value="Isle of Man">Isle of Man</StyledOption>
                <StyledOption value="Israel">Israel</StyledOption>
                <StyledOption value="Italy">Italy</StyledOption>
                <StyledOption value="Jamaica">Jamaica</StyledOption>
                <StyledOption value="Japan">Japan</StyledOption>
                <StyledOption value="Jordan">Jordan</StyledOption>
                <StyledOption value="Kazakhstan">Kazakhstan</StyledOption>
                <StyledOption value="Kenya">Kenya</StyledOption>
                <StyledOption value="Kiribati">Kiribati</StyledOption>
                <StyledOption value="Korea North">Korea North</StyledOption>
                <StyledOption value="Korea Sout">Korea South</StyledOption>
                <StyledOption value="Kuwait">Kuwait</StyledOption>
                <StyledOption value="Kyrgyzstan">Kyrgyzstan</StyledOption>
                <StyledOption value="Laos">Laos</StyledOption>
                <StyledOption value="Latvia">Latvia</StyledOption>
                <StyledOption value="Lebanon">Lebanon</StyledOption>
                <StyledOption value="Lesotho">Lesotho</StyledOption>
                <StyledOption value="Liberia">Liberia</StyledOption>
                <StyledOption value="Libya">Libya</StyledOption>
                <StyledOption value="Liechtenstein">Liechtenstein</StyledOption>
                <StyledOption value="Lithuania">Lithuania</StyledOption>
                <StyledOption value="Luxembourg">Luxembourg</StyledOption>
                <StyledOption value="Macau">Macau</StyledOption>
                <StyledOption value="Macedonia">Macedonia</StyledOption>
                <StyledOption value="Madagascar">Madagascar</StyledOption>
                <StyledOption value="Malaysia">Malaysia</StyledOption>
                <StyledOption value="Malawi">Malawi</StyledOption>
                <StyledOption value="Maldives">Maldives</StyledOption>
                <StyledOption value="Mali">Mali</StyledOption>
                <StyledOption value="Malta">Malta</StyledOption>
                <StyledOption value="Marshall Islands">Marshall Islands</StyledOption>
                <StyledOption value="Martinique">Martinique</StyledOption>
                <StyledOption value="Mauritania">Mauritania</StyledOption>
                <StyledOption value="Mauritius">Mauritius</StyledOption>
                <StyledOption value="Mayotte">Mayotte</StyledOption>
                <StyledOption value="Mexico">Mexico</StyledOption>
                <StyledOption value="Midway Islands">Midway Islands</StyledOption>
                <StyledOption value="Moldova">Moldova</StyledOption>
                <StyledOption value="Monaco">Monaco</StyledOption>
                <StyledOption value="Mongolia">Mongolia</StyledOption>
                <StyledOption value="Montserrat">Montserrat</StyledOption>
                <StyledOption value="Morocco">Morocco</StyledOption>
                <StyledOption value="Mozambique">Mozambique</StyledOption>
                <StyledOption value="Myanmar">Myanmar</StyledOption>
                <StyledOption value="Nambia">Nambia</StyledOption>
                <StyledOption value="Nauru">Nauru</StyledOption>
                <StyledOption value="Nepal">Nepal</StyledOption>
                <StyledOption value="Netherland Antilles">Netherland Antilles</StyledOption>
                <StyledOption value="Netherlands">Netherlands (Holland, Europe)</StyledOption>
                <StyledOption value="Nevis">Nevis</StyledOption>
                <StyledOption value="New Caledonia">New Caledonia</StyledOption>
                <StyledOption value="New Zealand">New Zealand</StyledOption>
                <StyledOption value="Nicaragua">Nicaragua</StyledOption>
                <StyledOption value="Niger">Niger</StyledOption>
                <StyledOption value="Nigeria">Nigeria</StyledOption>
                <StyledOption value="Niue">Niue</StyledOption>
                <StyledOption value="Norfolk Island">Norfolk Island</StyledOption>
                <StyledOption value="Norway">Norway</StyledOption>
                <StyledOption value="Oman">Oman</StyledOption>
                <StyledOption value="Pakistan">Pakistan</StyledOption>
                <StyledOption value="Palau Island">Palau Island</StyledOption>
                <StyledOption value="Palestine">Palestine</StyledOption>
                <StyledOption value="Panama">Panama</StyledOption>
                <StyledOption value="Papua New Guinea">Papua New Guinea</StyledOption>
                <StyledOption value="Paraguay">Paraguay</StyledOption>
                <StyledOption value="Peru">Peru</StyledOption>
                <StyledOption value="Phillipines">Philippines</StyledOption>
                <StyledOption value="Pitcairn Island">Pitcairn Island</StyledOption>
                <StyledOption value="Poland">Poland</StyledOption>
                <StyledOption value="Portugal">Portugal</StyledOption>
                <StyledOption value="Puerto Rico">Puerto Rico</StyledOption>
                <StyledOption value="Qatar">Qatar</StyledOption>
                <StyledOption value="Republic of Montenegro">Republic of Montenegro</StyledOption>
                <StyledOption value="Republic of Serbia">Republic of Serbia</StyledOption>
                <StyledOption value="Reunion">Reunion</StyledOption>
                <StyledOption value="Romania">Romania</StyledOption>
                <StyledOption value="Russia">Russia</StyledOption>
                <StyledOption value="Rwanda">Rwanda</StyledOption>
                <StyledOption value="St Barthelemy">St Barthelemy</StyledOption>
                <StyledOption value="St Eustatius">St Eustatius</StyledOption>
                <StyledOption value="St Helena">St Helena</StyledOption>
                <StyledOption value="St Kitts-Nevis">St Kitts-Nevis</StyledOption>
                <StyledOption value="St Lucia">St Lucia</StyledOption>
                <StyledOption value="St Maarten">St Maarten</StyledOption>
                <StyledOption value="St Pierre &amp; Miquelon">St Pierre &amp; Miquelon</StyledOption>
                <StyledOption value="St Vincent &amp; Grenadines">St Vincent &amp; Grenadines</StyledOption>
                <StyledOption value="Saipan">Saipan</StyledOption>
                <StyledOption value="Samoa">Samoa</StyledOption>
                <StyledOption value="Samoa American">Samoa American</StyledOption>
                <StyledOption value="San Marino">San Marino</StyledOption>
                <StyledOption value="Sao Tome & Principe">Sao Tome & Principe</StyledOption>
                <StyledOption value="Saudi Arabia">Saudi Arabia</StyledOption>
                <StyledOption value="Senegal">Senegal</StyledOption>
                <StyledOption value="Seychelles">Seychelles</StyledOption>
                <StyledOption value="Sierra Leone">Sierra Leone</StyledOption>
                <StyledOption value="Singapore">Singapore</StyledOption>
                <StyledOption value="Slovakia">Slovakia</StyledOption>
                <StyledOption value="Slovenia">Slovenia</StyledOption>
                <StyledOption value="Solomon Islands">Solomon Islands</StyledOption>
                <StyledOption value="Somalia">Somalia</StyledOption>
                <StyledOption value="South Africa">South Africa</StyledOption>
                <StyledOption value="Spain">Spain</StyledOption>
                <StyledOption value="Sri Lanka">Sri Lanka</StyledOption>
                <StyledOption value="Sudan">Sudan</StyledOption>
                <StyledOption value="Suriname">Suriname</StyledOption>
                <StyledOption value="Swaziland">Swaziland</StyledOption>
                <StyledOption value="Sweden">Sweden</StyledOption>
                <StyledOption value="Switzerland">Switzerland</StyledOption>
                <StyledOption value="Syria">Syria</StyledOption>
                <StyledOption value="Tahiti">Tahiti</StyledOption>
                <StyledOption value="Taiwan">Taiwan</StyledOption>
                <StyledOption value="Tajikistan">Tajikistan</StyledOption>
                <StyledOption value="Tanzania">Tanzania</StyledOption>
                <StyledOption value="Thailand">Thailand</StyledOption>
                <StyledOption value="Togo">Togo</StyledOption>
                <StyledOption value="Tokelau">Tokelau</StyledOption>
                <StyledOption value="Tonga">Tonga</StyledOption>
                <StyledOption value="Trinidad &amp; Tobago">Trinidad &amp; Tobago</StyledOption>
                <StyledOption value="Tunisia">Tunisia</StyledOption>
                <StyledOption value="Turkey">Turkey</StyledOption>
                <StyledOption value="Turkmenistan">Turkmenistan</StyledOption>
                <StyledOption value="Turks &amp; Caicos Is">Turks &amp; Caicos Is</StyledOption>
                <StyledOption value="Tuvalu">Tuvalu</StyledOption>
                <StyledOption value="Uganda">Uganda</StyledOption>
                <StyledOption value="United Kingdom">United Kingdom</StyledOption>
                <StyledOption value="Ukraine">Ukraine</StyledOption>
                <StyledOption value="United Arab Erimates">United Arab Emirates</StyledOption>
                <StyledOption value="United States of America">United States of America</StyledOption>
                <StyledOption value="Uraguay">Uruguay</StyledOption>
                <StyledOption value="Uzbekistan">Uzbekistan</StyledOption>
                <StyledOption value="Vanuatu">Vanuatu</StyledOption>
                <StyledOption value="Vatican City State">Vatican City State</StyledOption>
                <StyledOption value="Venezuela">Venezuela</StyledOption>
                <StyledOption value="Vietnam">Vietnam</StyledOption>
                <StyledOption value="Virgin Islands (Brit)">Virgin Islands (Brit)</StyledOption>
                <StyledOption value="Virgin Islands (USA)">Virgin Islands (USA)</StyledOption>
                <StyledOption value="Wake Island">Wake Island</StyledOption>
                <StyledOption value="Wallis &amp; Futana Is">Wallis &amp; Futana Is</StyledOption>
                <StyledOption value="Yemen">Yemen</StyledOption>
                <StyledOption value="Zaire">Zaire</StyledOption>
                <StyledOption value="Zambia">Zambia</StyledOption>
                <StyledOption value="Zimbabwe">Zimbabwe</StyledOption>
              </StyledSelect>
              {errors.country && touched.country ? (
            <span className="error-message">{errors.country}</span>
          ) : null}
            </StyledLabel>
          </StyledFormElementWrapper>
        </StyledSelectWrapper>


        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="howHear">
            <StyledLabelHeader
              required
              isEmpty={errors.howHear && touched.howHear}
            >How did you hear about Karina?</StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="howHear"
                name="howHear"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                >
                <StyledOption value="default" disabled hidden>Please select?</StyledOption>  
                <StyledOption value="a friend">From a friend</StyledOption>
                <StyledOption value="Facebook">FaceBook</StyledOption>
                <StyledOption value="Google Search">Google Search</StyledOption>
                <StyledOption value="Google Advert">Google Advert</StyledOption>
                <StyledOption value="LinkedIn">LinkedIn</StyledOption>
                <StyledOption value="HI CHI">HI CHI Health &amp; Wellness</StyledOption>
                <StyledOption value="WDDTY">WDDTY</StyledOption>
                <StyledOption value="MeetUp">MeetUp</StyledOption>
                <StyledOption value="AllEvents">AllEvents</StyledOption>
                <StyledOption value="EventBrite">EventBrite</StyledOption>
                <StyledOption value="FHTJ">FHTJ</StyledOption>
                <StyledOption value="other">Other</StyledOption>
              </StyledSelect>
              {errors.howHear && touched.howHear ? (
            <span className="error-message">{errors.howHear}</span>
          ) : null}
            </StyledLabel>
          </StyledFormElementWrapper>
        </StyledSelectWrapper>

        {isHowHeardOther && 
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="howHearOther">
          <StyledLabelHeader
              required
              isEmpty={errors.howHearOther && touched.howHearOther}
            >Please can you let us know how you heard about Karina?</StyledLabelHeader>
            <StyledTextArea
              id="howHearOther"
              name="howHearOther"
              type="input"
              placeholder=""
              onChange={handleChange}
              value={values.howHearOther}
              onBlur={handleBlur("howHearOther")}
              required
            />
          </StyledLabel>
        </StyledFormElementWrapper>
        }
        
    </StyledFieldset>
    <StyledButton
      type="submit"
      value="Send Message"
      disabled={isSubmitting || !isFormValid}
    >
      Submit
      </StyledButton>
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
  </StyledForm>
  )
}

export default FormPermissioningRegister
